import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { getStringFromElement, initializeHubspotForm, stringToSlug } from "../utils/gatsby-helpers"

import NewLayout from "../components/new_layout"
import Menu from "../components/v2023/Blog/Menu/menu"
import Seo from "../components/seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import BlogImage from "../components/v2023/UI/Blog/blog-image"
import BlogCta from "../components/v2023/UI/Blog/blog-cta"
import Newsletter from "../components/v2023/Newsletter/newsletter"
import Certifications from "../components/v2023/Certifications/certifications"
import Identity from "../components/v2023/Identity/identity"
import Toolbar from "../components/v2023/UI/Card/toolbar"
import { Modal } from "antd"
import YoutubeVideo from "../components/v2024/video/youtube-video"


const NewBlogArticle = ({ data, location, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page.edges[0].node
    const categories = data.categories.edges
    const blog = data.blog.frontmatter
    const post = data.post

    const [showMenu, setShowMenu] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showAdModal, setShowAdModal] = useState(false)

    const path = {
        "en": "/blog",
        "es": "/es/blog"
    }

    const updateTitle = {
        "en": "Updated",
        "es": "Actualizado"
    }

    const schemaMarkup = [
        {
            "@context": "http://schema.org",
            "@type": "Article",
            author: {
                "@type": "Organization",
                name: "Tecalis"
            },
            copyrightHolder: {
                "@type": "Organization",
                name: "Tecalis"
            },
            copyrightYear: new Date().getFullYear(),
            creator: {
                "@type": "Organization",
                name: "Tecalis"
            },
            publisher: {
                "@type": "Organization",
                name: "Tecalis",
                logo: {
                    "@type": "ImageObject",
                    url: require("../images/tecalis-share-2022.png").default
                }
            },
            datePublished: post.published_at,
            dateModified: post.updated_at,
            description: post.seo.meta_description,
            headline: post.seo.title,
            inLanguage: pageContext.langKey,
            url: `https://tecalis.com/${path[pageContext.langKey]}/${post.url}`,
            name: post.seo.title,
            image: {
                "@type": "ImageObject",
                url: post.seo.image_2.localFile.publicURL
            },
            mainEntityOfPage: `https://tecalis.com/${path[pageContext.langKey]}/${post.url}`,
            alternateName: ""
        }
    ]

    const form = {
        "en": {
            region: "na1",
            portalId: "20249583",
            formId: "4ccd2e79-9836-4b03-a50a-25389d57a194"
        },
        "es": {
            region: "na1",
            portalId: "20249583",
            formId: "a36d099d-9a65-4224-b3ca-337dfb87a90e"
        }
    }

    const elements = {
        h2: ({ node, ...props }) => {
            const element = <h2 {...props} />
            const stringElement = getStringFromElement(element)

            return <h2 id={stringToSlug(stringElement)} {...props} />
        },
        li: ({node, ...props}) => {
            return <li {...props}><div>{props.children}</div></li>
        },
        iframe: ({ node, ...props }) => {
            return <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" {...props} />
            </div>
        }
    }

    const openAdModal = (ad_form) => {
        setShowAdModal(true)
        initializeHubspotForm(ad_form.portal_id, ad_form.form_id, "#blog-ad-form", ad_form.region)
    }

    initializeHubspotForm(form[lang].portalId, form[lang].formId, "#index-form-container", form[lang].region)

    let indexItems = []

    post.content.map((item) => {
        if (item.strapi_component === "blog.content-rich-text" && typeof document !== 'undefined') {
            const e = document.createElement("div")

            e.innerHTML = item.text

            e.querySelectorAll("h2").forEach((h2, index) => {
                indexItems.push(h2.innerText)
            })
        }
    })

    let infoMetaData = [<span>{post.creation_datetime}</span>] ?? [<span>{post.published_at}</span>]

    if (post.modification_date) {
        infoMetaData.push(<span>·</span>)
        infoMetaData.push(<span>{updateTitle[lang]} {post.modification_date}</span>)
    }

    if (post.reading_time) {
        infoMetaData.push(<span>·</span>)
        infoMetaData.push(<span>{post.reading_time}</span>)
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={lang}
                title={post.seo.title}
                description={post.seo.meta_description}
                image={post.seo?.image_2?.localFile?.publicURL}
                translates={pageContext.translates}
                schemaMarkup={schemaMarkup}
            />

            <main className="main">
                <Menu categories={categories} blog={blog} lang={lang} />

                <div className="main__section main__section--100-post" data-aos="fade-up">
                    <div className="container">
                        <div className="tag-group hidden-sm">
                            {post.blog_categories.slice(0, 2).map((category, index) => {
                                return <Link to={`${path[lang]}/${category.url}`} className="tag tag--lg" key={index}>{category.name}</Link>
                            })}
                        </div>
                        <h1 className="thin">{post.name}</h1>
                        <GatsbyImage alt={post.photo_2.alternativeText} image={getImage(post.photo_2.localFile)} />
                        <div className="post-detail">
                            <div className="post-detail__info">
                                <div className="post-detail__info__metadata">
                                    {infoMetaData.map((item => item))}
                                </div>
                                <div className="post-detail__info__language">
                                    <div className="tabs tabs--pills">
                                        {pageContext.translates && Object.entries(pageContext.translates).map(([key, value]) => {
                                            return <Link to={value} className={`tabs__tab ${key === lang ? "tabs__tab--active" : ""}`} key={key}>{key}</Link>
                                        })}
                                    </div>
                                </div>
                            </div>

                            <Toolbar lang={lang} title={post.name} url={location.href} />
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100-post-content" data-aos="fade-up">
                    <div className="container">
                        <div className="container__sticky">
                            <h4>{lang === "es" ? "Índice" : "Index"}</h4>
                            <ol>
                                {indexItems.map((item, index) => {
                                    return <li key={index}>
                                        <a href={`#${stringToSlug(item)}`}>{item}</a>
                                    </li>
                                })}
                            </ol>
                            <div className="tile tile--white">
                                <StaticImage
                                    src={"../../../new/resources/home/newsletter.svg"}
                                    alt="icon newsletter"
                                />
                                <h6>{blog.newsletter.title}</h6>
                                <button onClick={() => setShowModal(true)} className="button button--sm">{blog.subscribe}</button>
                            </div>
                            <Modal
                                centered
                                visible={showModal}
                                onCancel={() => setShowModal(false)}
                                width={"100%"}
                                footer={null}
                                className="newsletter"
                            >
                                <div>
                                    <StaticImage
                                        src={"../../../../new/resources/home/newsletter.svg"}
                                        title="icon newsletter"
                                        alt="icon newsletter"
                                        quality={100}
                                        placeholder="blurred"
                                    />
                                </div>
                                <h4>{blog.newsletter.title}</h4>
                                <div id="blog-form-container" className="form-hb form-hb--newsletter" />
                            </Modal>
                        </div>
                        <div className="container__content">
                            {post.content.map((item, index) => {
                                switch (item.strapi_component) {
                                    case "blog.content-rich-text":
                                        return <ReactMarkdown children={item.text} rehypePlugins={[rehypeRaw]} components={elements} />
                                    case "blog.image":
                                        return <BlogImage
                                            image={pageContext.images[item.image.localFile___NODE].data.image}
                                            title={item.title}
                                            alt={item.alt}
                                            figcaption={item.figcaption}
                                            link={item.link}
                                            blank={item.blank} />
                                    case "blog.advertising":
                                        if (item.orientation === "horizontal") {
                                            if (pageContext.advertisementsForms[item.blog_ad.id]) {
                                                return <div className="banner" onClick={() => openAdModal(pageContext.advertisementsForms[item.blog_ad.id])}>
                                                    <GatsbyImage image={getImage(pageContext.advertisements[item.blog_ad.image.localFile___NODE].data.image)} alt={item.blog_ad.image.alternativeText} />
                                                </div>
                                            }

                                            return <a href={item.blog_ad.link} target="_blank" key={index}>
                                                <div className="hidden-sm">
                                                    <GatsbyImage image={getImage(pageContext.advertisements[item.blog_ad.image.localFile___NODE].data.image)} alt={item.blog_ad.image.alternativeText} />
                                                </div>
                                                <div className="show-sm">
                                                    <GatsbyImage image={getImage(pageContext.advertisements[item.blog_ad.image.localFile___NODE].data.mobile_image)} alt={item.blog_ad.mobile_image.alternativeText} />
                                                </div>
                                            </a>
                                        }
                                        break
                                    case "blog.cta":
                                        return <BlogCta data={item} image={pageContext.ctas[item.image?.localFile___NODE]?.data?.image} key={index} />
                                    case "general.video":
                                        return <YoutubeVideo preview={{localFile: pageContext.videos[item.video_preview.localFile___NODE].data.video_preview, alternativeText: 'test'}} video={item.video_url} key={index} />
                                }
                            })}

                            <h5>{lang === "es" ? "Etiquetas" : "Tags"}</h5>
                            <div className="tag-group ai-flex-start">
                                {post.blog_categories.map((category, index) => {
                                    return <Link to={`${path[lang]}/${category.url}`} className="tag tag--xs" key={index}>{category.name}</Link>
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <Newsletter lang={lang} />

                <Certifications />

                <Identity lang={lang} />

                <Modal
                    centered
                    visible={showAdModal}
                    onCancel={() => setShowAdModal(false)}
                    width={"100%"}
                    footer={null}
                >
                    <div>
                        <div id="blog-ad-form" className="form-hb form-hb--blog" />
                    </div>
                </Modal>
            </main>
        </NewLayout>

    )
}

export default NewBlogArticle

export const blogArticleQuery = graphql`
    query ($id: String!, $langKey: String!) {
        page: allStrapiBlog (filter: { locale: { eq: $langKey } }) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                    }
                    title
                    search_title
                    search_placeholder
                    search_button
                    search_show_all
                }
            }
        }
        categories: allStrapiBlogCategories (filter: { locale: { eq: $langKey } }) {
            edges {
                node {
                    id
                    name
                    url
                }
            }
        }
        blog: markdownRemark(
            frontmatter: {
                lang: {
                    eq: $langKey
                }
                name: {
                    eq: "blog"
                }
            }) {
            frontmatter {
                url
                subscribe
                see_more
                see_less
                view_more
                recommended
                explore
                newsletter {
                    title
                    description
                }
            }
        }
        post: strapiBlogPosts (locale: {eq: $langKey}, id: {eq: $id}) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL

                    }
                }
            }
            id
            name
            url
            creation_datetime(formatString: "DD MMMM YYYY", locale: $langKey)
            published_at(formatString: "DD MMMM YYYY", locale: $langKey)
            creation_date
            modification_date
            photo_2 {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1175
                            quality: 100
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            content
            blog_categories {
                id
                name
                url
            }
            reading_time
        }
        site {
            siteMetadata {
                title
                twitterHandle
            }
        }
    }
`
